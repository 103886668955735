import React from "react";
import { orderBy, sampleSize } from "lodash";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import DynamicSidebar from "../components/DynamicSidebar";
import BlogLists from "../components/BlogLists";
import Default from "../_contenthub/website.json";
import { default as contentData } from "../../static/generated-content/blogs.json";

const { introBlockBlogBlogPage } = Default;

const data = contentData;
const dataFeatured = [data[data.length - 5]];
const dataList = data
  ? orderBy(sampleSize(data, 7), ["publishedAt"], ["desc"])
  : data;

export const Head = () => {
  const pickSingleImg = dataList[2].img;

  return (
    <HeadSeo
      title={`${introBlockBlogBlogPage.title}`}
      description={`We have an extensive list of blog articles available for you to read, so browse through our various topics and share the articles you like.`}
      seoImg={`${pickSingleImg}`}
    ></HeadSeo>
  );
};

const BlogPage = () => {
  if (!dataFeatured || !dataList) return null;

  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  {/* -- */}
                  <BlogLists
                    data={dataFeatured}
                    layoutType={`featured-block`}
                  />

                  <div className="intro-wrap intro-wrap-accent-1 welcome-blog-block">
                    <h1>{introBlockBlogBlogPage.title}</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: introBlockBlogBlogPage.text,
                      }}
                    />
                    <div className="big-started">
                      <span className="img">
                        <img
                          src="/cta-icon.png"
                          loading="lazy"
                          alt="get started"
                        />
                      </span>
                      <a href="/blog/1/">Let's get started</a>
                    </div>
                  </div>

                  {/* -- */}
                  <BlogLists
                    data={dataList}
                    limit="6"
                    layoutType={`grid-top`}
                  />
                </div>

                {/* - - -  */}
                <DynamicSidebar onlyNews="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default BlogPage;
