import React, { Component } from "react";
import FetchNewsBlock from "../components/FetchNewsBlock";
import BlogLists from "../components/BlogLists";
import ShareWidget from "../components/ShareWidget";

class DynamicSidebar extends Component {
  renderBlogs() {
    const filterableTitle = this?.props?.filterableTitle;
    const onlyShowNewsBlock = this?.props?.onlyNews;

    if (onlyShowNewsBlock) return null;

    return (
      <>
        <h4>Popular Blog Posts</h4>
        <BlogLists
          limit="4"
          filterableTitle={filterableTitle ?? false}
          layoutType={`sideblock-lists`}
        ></BlogLists>
        <div>
          <a href="/blog/1/" className="cta-blog-side-right">
            All Blog Posts <i className="fa-regular fa-circle-right"></i>
          </a>
        </div>
      </>
    );
  }

  renderNews() {
    const onlyShowBlogBlock = this?.props?.onlyBlogs;
    if (onlyShowBlogBlock) return null;

    return (
      <>
        <h4>Latest News</h4>
        <FetchNewsBlock limit="3" layoutType="sidebar-news" />
      </>
    );
  }

  render() {
    return (
      <div className="right">
        <div> {this.renderBlogs()}</div>
        <div> {this.renderNews()}</div>
        <div>
          <ShareWidget></ShareWidget>
        </div>
      </div>
    );
  }
}

export default DynamicSidebar;
