import React, { Component } from "react";

class ShareWidget extends Component {
  render() {
    return (
      <div>
        {/* https://www.addtoany.com/share // https://www.addthis.com/dashboard#gallery/pub/ra-4f9872c04ddc1216  */}
        <div className="lil-widget">
          <div className="addthis_inline_share_toolbox_h3i8"></div>
        </div>
      </div>
    );
  }
}
export default ShareWidget;
